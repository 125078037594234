import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function Login(props) {
    const router = useRouter();

    useEffect(() => {
        router.push('/order');
    }, []);

    return <div className=""></div>;
}
